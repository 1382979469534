<template>
  <div class="bag-style-settings">
    <v-row>
      <v-col :cols="12" :md="3">
        <h2>Bag Styles</h2>
      </v-col>
      <v-col :cols="12" :md="9">
        <base-card v-if="isLoading">
          <div class="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular></div
        ></base-card>

        <base-card v-if="!isLoading" class="overflow-hidden">
          <!-- No bag styles to show -->
          <v-card-text v-if="bagStyles.length == 0">
            <div class="px-1 py-1">
              <p class="pt-2 mb-2">Nothing to show here</p>
            </div>
          </v-card-text>
          <!-- / No bag styles to show -->

          <!-- Bag styles to show -->
          <v-data-table
            v-if="bagStyles.length"
            :headers="headers"
            :items="bagStyles"
            :disable-pagination="true"
            class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn
                small
                depressed
                class="me-2"
                @click.native.prevent="() => attemptToEdit(item)"
              >
                <v-icon small class="me-2">mdi-pencil</v-icon>
                Edit</v-btn
              >

              <v-btn
                small
                depressed
                color="danger"
                dark
                @click.native.prevent="() => attemptToDelete(item.id)"
              >
                <v-icon small class="me-2">mdi-delete</v-icon>
                Delete</v-btn
              >
            </template>
          </v-data-table>
          <!-- / Deductions to show -->
        </base-card>

        <add-bag-style-button-and-modal
          v-if="!isLoading"
          @refresh="fetch"
          class="mt-5"
        ></add-bag-style-button-and-modal>
      </v-col>
    </v-row>

    <!-- Attempt to Delete -->
    <v-dialog v-model="showDeleteModal" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5 mb-4"
          >Please Confirm Deletion</v-card-title
        >
        <v-card-text>
          Are you sure you want to delete
          <b>{{
            attemptingToDelete ? attemptingToDelete.description : "Unknown"
          }}</b
          >?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isDeleting"
            color="primary darken-1"
            text
            @click="attemptingToDeleteId = null"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="isDeleting"
            :loading="isDeleting"
            color="red darken-1"
            text
            @click="attemptDelete"
          >
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Attempt to Delete -->

    <!-- Attempting to Edit -->
    <v-dialog v-model="showEditModal" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5 mb-4">Edit Bag Style</v-card-title>
        <v-card-text>
          <v-form
            ref="editForm"
            @submit.prevent="attemptEdit"
            v-if="attemptingToEdit"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Description"
                  :disabled="isSaving"
                  v-model="attemptingToEdit.description"
                  :rules="descriptionRules"
                  required
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isSaving"
            color="red darken-1"
            text
            @click="attemptingToEdit = null"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="isSaving"
            :loading="isSaving"
            color="primary darken-1"
            text
            @click="attemptEdit"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Attempting to Edit -->
  </div>
</template>

<script>
const _ = require("lodash");
import Display from "../../mixins/Display";
import bagStyles from "../../api/settings/bag-styles";
import AddBagStyleButtonAndModal from "./BagStyles/AddBagStyleButtonAndModal.vue";
import { mapActions } from "vuex";

export default {
  components: { AddBagStyleButtonAndModal },

  mixins: [Display],

  data() {
    return {
      selectedTab: 0,
      isLoading: false,
      bagStyles: [],
      headers: [
        {
          text: "Description",
          align: "start",
          value: "description",
        },
        { text: "Actions", value: "actions", sortable: false, width: "230px" },
      ],

      attemptingToDeleteId: null,
      isDeleting: false,

      attemptingToEdit: null,
      isSaving: false,

      descriptionRules: [(v) => !!v || "Description is required"],
    };
  },

  created() {
    this.fetch(false);
  },

  methods: {
    ...mapActions("context", {
      loadContext: "loadContext",
    }),

    fetch(shouldLoadContext = true) {
      let vm = this;
      vm.isLoading = true;
      vm.bagStyles = [];
      bagStyles
        .getAll()
        .then((r) => {
          vm.isLoading = false;
          vm.bagStyles = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$toast.error("Error loading bag styles");
        });

      if (shouldLoadContext) {
        this.loadContext();
      }
    },

    attemptToDelete(id) {
      this.attemptingToDeleteId = id;
    },

    attemptDelete() {
      if (!this.attemptingToDeleteId) {
        return false;
      }
      let vm = this;
      vm.isDeleting = true;
      bagStyles
        .delete(this.attemptingToDeleteId)
        .then((r) => {
          vm.isDeleting = false;
          vm.$toast.success("Bag style deleted successfully");
          vm.attemptingToDeleteId = null;
          vm.fetch();
        })
        .catch((e) => {
          console.log(e);
          vm.isDeleting = false;
          vm.$toast.error("Error deleting bag style. Please try again.");
        });
    },

    attemptToEdit(item) {
      this.attemptingToEdit = {
        ...item,
      };
    },

    attemptEdit() {
      let isValid = this.$refs["editForm"].validate();
      if (isValid) {
        this.persistChanges();
      }
    },

    persistChanges() {
      let vm = this;
      vm.isSaving = true;

      let params = {
        ...this.attemptingToEdit,
      };

      bagStyles
        .update(this.attemptingToEdit.id, params)
        .then(() => {
          vm.isSaving = false;
          vm.$toast.success("Bag style updated successfully");
          vm.attemptingToEdit = null;
          vm.fetch();
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          let errors = vm.getErrorsAsArray(e);
          let error;
          if (errors.length) {
            error = _.first(errors);
          } else {
            error = "Something went wrong. Please try again.";
          }
          vm.$toast.error(error);
        });
    },
  },

  computed: {
    showDeleteModal: {
      get() {
        return this.attemptingToDeleteId !== null;
      },
      set(val) {
        if (!val) {
          this.attemptingToDeleteId = null;
        }
      },
    },

    attemptingToDelete() {
      if (this.attemptingToDeleteId) {
        return _.find(this.bagStyles, { id: this.attemptingToDeleteId });
      }
      return null;
    },

    showEditModal: {
      get() {
        return this.attemptingToEdit !== null;
      },
      set(val) {
        if (!val) {
          this.attemptingToEdit = null;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.bag-style-settings {
  .loading {
    text-align: center;
    padding: 25px;
  }

  h2 {
    font-size: 1.5rem;
  }

  .border-bottom {
    border-bottom: 1px solid #efecf3 !important;
  }

  tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
}
</style>