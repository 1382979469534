<template>
  <div>
    <v-btn dark depressed @click.prevent="openModal">
      <v-icon class="me-2">mdi-plus</v-icon>
      Add Bag Condition
    </v-btn>

    <v-dialog v-model="modalVisible" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5 mb-4">Add Bag Condition</v-card-title>

        <v-card-text>
          <v-form ref="form" @submit.prevent="attemptAddBagCondition">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  :disabled="isSaving"
                  v-model="bagCondition.description"
                  :rules="descriptionRules"
                  required
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isSaving"
            color="red darken-1"
            text
            @click="modalVisible = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="isSaving"
            :loading="isSaving"
            color="primary darken-1"
            text
            @click="attemptAddBagCondition"
          >
            Add Bag Condition
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Display from "../../../mixins/Display";
import Forms from "../../../mixins/Forms";
import bagConditions from "../../../api/settings/bag-conditions";
const _ = require("lodash");
export default {
  props: ["currency"],
  mixins: [Display, Forms],
  data() {
    return {
      modalVisible: false,
      isSaving: false,
      bagCondition: {
        description: "",
        amount: null,
      },

      descriptionRules: [(v) => !!v || "Description is required"]
    };
  },
  methods: {
    openModal() {
      this.modalVisible = true;
    },

    resetForm() {
      this.bagCondition = {
        description: ""
      };
    },

    attemptAddBagCondition() {
      let isValid = this.$refs["form"].validate();
      if (isValid) {
        this.addBagCondition();
      }
    },

    addBagCondition() {
      let vm = this;
      vm.isSaving = true;

      let params = {
        description: this.bagCondition.description
      };

      bagConditions
        .store(params)
        .then((r) => {
          vm.isSaving = false;
          vm.$toast.success("Bag condition added successfully");
          vm.$emit("refresh");
          vm.modalVisible = false;
        })
        .catch((e) => {
          console.log(e);
          let errors = vm.getErrorsAsArray(e);
          vm.$toast.error(_.first(errors));
          vm.isSaving = false;
        });
    },
  },
};
</script>

<style>
</style>