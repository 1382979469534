<template>
  <div>
    <v-btn dark depressed @click.prevent="openModal">
      <v-icon class="me-2">mdi-plus</v-icon>
      Add Deduction
    </v-btn>

    <v-dialog v-model="modalVisible" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5 mb-4">Add Deduction</v-card-title>

        <v-card-text>
          <v-form ref="form" @submit.prevent="attemptAddDeduction">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Name"
                  :disabled="isSaving"
                  v-model="deduction.description"
                  :rules="descriptionRules"
                  required
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :disabled="isSaving"
                  :prefix="currencyCodeToSymbol(currency)"
                  label="Amount"
                  v-model="deduction.amount"
                  :rules="amountRules"
                  required
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isSaving"
            color="red darken-1"
            text
            @click="modalVisible = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="isSaving"
            :loading="isSaving"
            color="primary darken-1"
            text
            @click="attemptAddDeduction"
          >
            Add Deduction
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Display from "../../../mixins/Display";
import Forms from "../../../mixins/Forms";
import deductions from "../../../api/settings/deductions";
const _ = require("lodash");
export default {
  props: ["currency"],
  mixins: [Display, Forms],
  data() {
    return {
      modalVisible: false,
      isSaving: false,
      deduction: {
        description: "",
        amount: null,
      },

      descriptionRules: [(v) => !!v || "Description is required"],

      amountRules: [
        (v) => !!v || "Amount is required",
        (v) => /^\d+(\.\d+)?$/.test(v) || "Must be a number",
        // (v) =>
        //   (v && v.toString().split(".")[0].length <= 5) ||
        //   "No more than 5 digits before the decimal point",
        (v) =>
          (v && v.toString().split(".").length < 2) ||
          (v && v.toString().split(".")[1].length <= 2) ||
          "No more than 2 digits after the decimal point",
      ],
    };
  },
  methods: {
    openModal() {
      this.modalVisible = true;
    },

    resetForm() {
      this.deduction = {
        description: "",
        amount: null,
      };
    },

    attemptAddDeduction() {
      let isValid = this.$refs["form"].validate();
      if (isValid) {
        this.addDeduction();
      }
    },

    addDeduction() {
      let vm = this;
      vm.isSaving = true;

      let params = {
        description: this.deduction.description,
        amount: Number(this.deduction.amount) * 100,
        currency: this.currency,
      };

      deductions
        .store(params)
        .then((r) => {
          vm.isSaving = false;
          vm.$toast.success("Deduction added successfully");
          vm.$emit("refresh");
          vm.modalVisible = false;
        })
        .catch((e) => {
          console.log(e);
          let errors = vm.getErrorsAsArray(e);
          vm.$toast.error(_.first(errors));
          vm.isSaving = false;
        });
    },
  },
};
</script>

<style>
</style>