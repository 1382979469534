<template>
  <div>
    <v-btn dark depressed @click.prevent="openModal">
      <v-icon class="me-2">mdi-plus</v-icon>
      Add Hardware
    </v-btn>

    <v-dialog v-model="modalVisible" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5 mb-4">Add Hardware</v-card-title>

        <v-card-text>
          <v-form ref="form" @submit.prevent="attemptAddHardware">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  :disabled="isSaving"
                  v-model="hardware.name"
                  :rules="nameRules"
                  required
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isSaving"
            color="red darken-1"
            text
            @click="modalVisible = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="isSaving"
            :loading="isSaving"
            color="primary darken-1"
            text
            @click="attemptAddHardware"
          >
            Add Hardware
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Display from "../../../mixins/Display";
import Forms from "../../../mixins/Forms";
import hardwares from "../../../api/settings/hardwares";
const _ = require("lodash");
export default {
  mixins: [Display, Forms],
  data() {
    return {
      modalVisible: false,
      isSaving: false,
      hardware: {
        name: ""
      },

      nameRules: [(v) => !!v || "Name is required"]
    };
  },
  methods: {
    openModal() {
      this.modalVisible = true;
    },

    resetForm() {
      this.hardware = {
        name: ""
      };
    },

    attemptAddHardware() {
      let isValid = this.$refs["form"].validate();
      if (isValid) {
        this.addHardware();
      }
    },

    addHardware() {
      let vm = this;
      vm.isSaving = true;

      let params = {
        name: this.hardware.name
      };

      hardwares
        .store(params)
        .then((r) => {
          vm.isSaving = false;
          vm.$toast.success("Hardware added successfully");
          vm.$emit("refresh");
          vm.modalVisible = false;
        })
        .catch((e) => {
          console.log(e);
          let errors = vm.getErrorsAsArray(e);
          vm.$toast.error(_.first(errors));
          vm.isSaving = false;
        });
    },
  },
};
</script>

<style>
</style>