import axios from 'axios';

export default {
    getAll() {
        return axios.get(window.API_BASE + '/settings/hardwares');
    },

    store(params) {
        return axios.post(window.API_BASE + '/settings/hardwares', params);
    },

    update(id, params) {
        return axios.put(window.API_BASE + '/settings/hardwares/' + id, params);
    },

    delete(id) {
        return axios.delete(window.API_BASE + '/settings/hardwares/' + id);
    }
}