<template>
  <div class="deduction-settings">
    <v-row>
      <v-col :cols="12" :md="3">
        <h2>Deductions</h2>
      </v-col>
      <v-col :cols="12" :md="9">
        <base-card v-if="isLoading">
          <div class="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular></div
        ></base-card>

        <base-card v-if="!isLoading" class="overflow-hidden">
          <v-tabs v-model="selected_tab">
            <v-tab v-for="(currency, i) in currencies" :key="i">
              {{ currency }}
            </v-tab>
          </v-tabs>
          <!-- No deductions to show -->
          <v-card-text v-if="deductionsToShow.length == 0">
            <div class="px-1 py-1">
              <p class="pt-2 mb-2">Nothing to show here</p>
            </div>
          </v-card-text>
          <!-- / No deductions to show -->

          <!-- Deductions to show -->
          <v-data-table
            v-if="deductionsToShow.length"
            :headers="headers"
            :items="deductionsToShow"
            :disable-pagination="true"
            class="elevation-1"
          >
            <template v-slot:item.amount="{ item }">
              {{ formatCostInPence2dp(item.amount, item.currency) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                small
                depressed
                class="me-2"
                @click.native.prevent="() => attemptToEdit(item)"
              >
                <v-icon small class="me-2">mdi-pencil</v-icon>
                Edit</v-btn
              >

              <v-btn
                small
                depressed
                color="danger"
                dark
                @click.native.prevent="() => attemptToDelete(item.id)"
              >
                <v-icon small class="me-2">mdi-delete</v-icon>
                Delete</v-btn
              >
            </template>
          </v-data-table>
          <!-- / Deductions to show -->
        </base-card>

        <add-deduction-button-and-modal
          v-if="!isLoading"
          @refresh="fetch"
          class="mt-5"
          :currency="selectedCurrency"
        ></add-deduction-button-and-modal>
      </v-col>
    </v-row>

    <!-- Attempt to Delete -->
    <v-dialog v-model="showDeleteModal" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5 mb-4"
          >Please Confirm Deletion</v-card-title
        >
        <v-card-text>
          Are you sure you want to delete
          <b>{{
            attemptingToDelete ? attemptingToDelete.description : "Unknown"
          }}</b
          >?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isDeleting"
            color="primary darken-1"
            text
            @click="attemptingToDeleteId = null"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="isDeleting"
            :loading="isDeleting"
            color="red darken-1"
            text
            @click="attemptDelete"
          >
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Attempt to Delete -->

    <!-- Attempting to Edit -->
    <v-dialog v-model="showEditModal" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5 mb-4">Edit Deduction</v-card-title>
        <v-card-text>
          <v-form
            ref="editForm"
            @submit.prevent="attemptEdit"
            v-if="attemptingToEdit"
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Name"
                  :disabled="isSaving"
                  v-model="attemptingToEdit.description"
                  :rules="descriptionRules"
                  required
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :disabled="isSaving"
                  :prefix="currencyCodeToSymbol(attemptingToEdit.currency)"
                  label="Amount"
                  v-model="attemptingToEdit.amount"
                  :rules="amountRules"
                  required
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isSaving"
            color="red darken-1"
            text
            @click="attemptingToEdit = null"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="isSaving"
            :loading="isSaving"
            color="primary darken-1"
            text
            @click="attemptEdit"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Attempting to Edit -->
  </div>
</template>

<script>
const _ = require("lodash");
const CURRENCIES = ["GBP", "EUR", "USD"];
import Display from "../../mixins/Display";
import deductions from "../../api/settings/deductions";
import AddDeductionButtonAndModal from "./DeductionSettings/AddDeductionButtonAndModal.vue";

export default {
  components: { AddDeductionButtonAndModal },

  mixins: [Display],

  data() {
    return {
      selectedTab: 0,
      isLoading: false,
      currencies: CURRENCIES,
      selectedCurrency: _.first(this.currencies),
      deductions: [],
      headers: [
        {
          text: "Description",
          align: "start",
          value: "description",
        },
        {
          text: "Amount",
          value: "amount",
        },
        { text: "Actions", value: "actions", sortable: false, width: "230px" },
      ],

      attemptingToDeleteId: null,
      isDeleting: false,

      attemptingToEdit: null,
      isSaving: false,

      descriptionRules: [(v) => !!v || "Description is required"],

      amountRules: [
        (v) => !!v || "Amount is required",
        (v) => /^\d+(\.\d+)?$/.test(v) || "Must be a number",
        // (v) =>
        //   (v && v.toString().split(".")[0].length <= 5) ||
        //   "No more than 5 digits before the decimal point",
        (v) =>
          (v && v.toString().split(".").length < 2) ||
          (v && v.toString().split(".")[1].length <= 2) ||
          "No more than 2 digits after the decimal point",
      ],
    };
  },

  created() {
    this.fetch();
  },

  methods: {
    fetch() {
      let vm = this;
      vm.isLoading = true;
      vm.deductions = [];
      deductions
        .getAll()
        .then((r) => {
          vm.isLoading = false;
          vm.deductions = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$toast.error("Error loading deductions");
        });
    },

    attemptToDelete(id) {
      this.attemptingToDeleteId = id;
    },

    attemptDelete() {
      if (!this.attemptingToDeleteId) {
        return false;
      }
      let vm = this;
      vm.isDeleting = true;
      deductions
        .delete(this.attemptingToDeleteId)
        .then((r) => {
          vm.isDeleting = false;
          vm.$toast.success("Deduction deleted successfully");
          vm.attemptingToDeleteId = null;
          vm.fetch();
        })
        .catch((e) => {
          console.log(e);
          vm.isDeleting = false;
          vm.$toast.error("Error deleting deduction. Please try again.");
        });
    },

    attemptToEdit(item) {
      this.attemptingToEdit = {
        ...item,
        amount: item.amount / 100,
      };
    },

    attemptEdit() {
      let isValid = this.$refs["editForm"].validate();
      if (isValid) {
        this.persistChanges();
      }
    },

    persistChanges() {
      let vm = this;
      vm.isSaving = true;

      let params = {
        ...this.attemptingToEdit,
        amount: Number(this.attemptingToEdit.amount) * 100,
      };

      deductions
        .update(this.attemptingToEdit.id, params)
        .then(() => {
          vm.isSaving = false;
          vm.$toast.success("Deduction updated successfully");
          vm.attemptingToEdit = null;
          vm.fetch();
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          let errors = vm.getErrorsAsArray(e);
          let error;
          if (errors.length) {
            error = _.first(errors);
          } else {
            error = "Something went wrong. Please try again.";
          }
          vm.$toast.error(error);
        });
    },
  },

  computed: {
    selected_tab: {
      get() {
        if (this.selectedCurrency) {
          return this.currencies.indexOf(this.selectedCurrency);
        }
        return null;
      },
      set(val) {
        let currency = this.currencies[val];
        if (currency) {
          this.selectedCurrency = currency;
        }
      },
    },

    deductionsToShow() {
      let vm = this;
      return _.filter(this.deductions, (deduction) => {
        return deduction.currency == vm.selectedCurrency;
      });
    },

    showDeleteModal: {
      get() {
        return this.attemptingToDeleteId !== null;
      },
      set(val) {
        if (!val) {
          this.attemptingToDeleteId = null;
        }
      },
    },

    attemptingToDelete() {
      if (this.attemptingToDeleteId) {
        return _.find(this.deductions, { id: this.attemptingToDeleteId });
      }
      return null;
    },

    showEditModal: {
      get() {
        return this.attemptingToEdit !== null;
      },
      set(val) {
        if (!val) {
          this.attemptingToEdit = null;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.deduction-settings {
  .loading {
    text-align: center;
    padding: 25px;
  }

  h2 {
    font-size: 1.5rem;
  }

  .border-bottom {
    border-bottom: 1px solid #efecf3 !important;
  }

  tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
}
</style>