<template>
    <div class="xero-settings">
        <v-row>
            <v-col :md="3" :cols="12">
                <h2>Xero</h2>
            </v-col>
            <v-col :md="9" :cols="12">
                <!-- Loading -->
                <base-card v-if="isLoading">
                    <div class="loading">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div>
                </base-card>
                <!-- / Loading -->

                <!-- Currencies -->
                <base-card v-if="!isLoading" class="overflow-hidden">
                    <v-data-table :headers="headers" :items="dataForTable" :disable-pagination="true"
                        class="elevation-1">

                        <template v-slot:item.linked="{ item }">
                            <v-icon class="success--text" v-if="item.linked">mdi-check</v-icon>
                            <v-icon class="danger--text" v-else>mdi-window-close</v-icon>
                        </template>

                        <!-- <template v-slot:item.actions>
                            <v-btn small depressed class="me-2" @click.native.prevent="() => attemptToLinkXero(item)">
                                <v-icon small class="me-2">mdi-link</v-icon>
                                Link Xero
                            </v-btn>
                        </template> -->

                    </v-data-table>
                </base-card>
                <!-- / Currencies -->
            </v-col>
        </v-row>
    </div>
</template>

<script>
const _ = require('lodash');
const CURRENCIES = ["GBP", "EUR", "USD"];
import xeroLinks from "../../api/xero-links";

export default {
    data() {
        return {
            currencies: CURRENCIES,

            isLoading: false,
            xeroLinks: [],

            headers: [
                {
                    text: "Currency",
                    align: "start",
                    value: "currency",
                },
                {
                    text: "Xero Linked?",
                    value: "linked"
                },
                // { text: "Actions", value: "actions", sortable: false, width: "230px" },
            ]
        }
    },
    created() {
        this.fetch();
    },
    computed: {
        dataForTable() {
            return _.map(this.currencies, currency => {
                return {
                    currency: currency,
                    linked: _.find(this.xeroLinks, {currency: currency}) !== undefined
                }
            });
        }
    },
    methods: {
        fetch() {
            let vm = this;
            vm.isLoading = true;
            vm.xeroLinks = [];
            xeroLinks.getAll().then(r => {
                vm.isLoading = false;
                vm.xeroLinks = r.data;
            }).catch(e => {
                console.log(e);
                vm.isLoading = false;
                vm.$toast.error("Error loading xero links");
            });
        }

        // attemptToLinkXero(item) {
            // alert('Link link baby');
        // }
    }
}
</script>

<style lang="scss">
.xero-settings {
    .loading {
        text-align: center;
        padding: 25px;
    }

    h2 {
        font-size: 1.5rem;
    }

    .border-bottom {
        border-bottom: 1px solid #efecf3 !important;
    }

    tbody {
        tr:hover {
            background-color: transparent !important;
        }
    }
}
</style>