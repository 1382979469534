<template>
  <div class="settings">
    <v-container>
      <div class="welcome">
        <h1>Settings</h1>
      </div>

      <deduction-settings></deduction-settings>
      <bag-colour-settings class="mt-7"></bag-colour-settings>
      <bag-style-settings class="mt-7"></bag-style-settings>
      <bag-skin-settings class="mt-7"></bag-skin-settings>
      <bag-size-settings class="mt-7"></bag-size-settings>
      <bag-condition-settings class="mt-7"></bag-condition-settings>
      <hardware-settings class="mt-7"></hardware-settings>
      <location-settings class="mt-7"></location-settings>
      <xero-settings class="mt-7"></xero-settings>

    </v-container>
  </div>
</template>

<script>
import DeductionSettings from "../../../components/Settings/DeductionSettings.vue";
import BagStyleSettings from "../../../components/Settings/BagStyleSettings.vue";
import BagSkinSettings from "../../../components/Settings/BagSkinSettings.vue";
import BagSizeSettings from "../../../components/Settings/BagSizeSettings.vue";
import BagConditionSettings from "../../../components/Settings/BagConditionSettings.vue";
import BagColourSettings from "../../../components/Settings/BagColourSettings.vue";
import HardwareSettings from "../../../components/Settings/HardwareSettings.vue";
import LocationSettings from "../../../components/Settings/LocationSettings.vue";
import XeroSettings from "../../../components/Settings/XeroSettings.vue";

export default {
  name: "Settings",
  components: { DeductionSettings, BagStyleSettings, BagSkinSettings, BagSizeSettings, BagColourSettings, BagConditionSettings, HardwareSettings, LocationSettings, XeroSettings },
};
</script>

<style lang="scss" scoped>
.loading {
  text-align: center;
  padding-top: 20px;
}

.settings {
  padding: 20px;

  .welcome {
    margin-bottom: 30px;
  }
}
</style>